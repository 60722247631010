
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { isRequired } from '@/helpers/validations';
  import { SetNewPasswordPayload } from '@/store/interfaces';

  const appStore = namespace('app');

  @Component
  export default class ResetPage extends Vue {
    isValid = false;
    password1 = '';
    password2 = '';

    get token(): string {
      return this.$route.query.token as string;
    }

    @appStore.Action
    setNewPassword!: (data: SetNewPasswordPayload) => Promise<void>;

    isRequired(val: string): boolean {
      return isRequired(val);
    }
  }
